import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"
import { ReactP5Wrapper } from "react-p5-wrapper";
import sketch from '../components/SketchCarattere';

export default class Carattere extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    <div>
                        <div style={{padding: "50px 50px 0 50px"}}>
                            <p className="collectionTitle">
                                Carattere
                            </p>
                            <a href="#">
                                Soon on artblocks studio
                            </a>
                            <br/><br/>
                            <div id='svgWrapper' style={{justifyContent: 'center', display: 'flex'}}>
                                <ReactP5Wrapper sketch={sketch}/>
                            </div>
                            <br/><br/>
                            <p>
                                It starts from nothing. <i>Let us then suppose the mind to have no ideas in it, to be like white paper with nothing written on it. </i>
                                And then perceptions arrive. Each a dot that drags itself until it forms the core element: the character.
                                Origin of language, divisibile yet essential atom.
                                Cells that grow with time, characters turn concrete and become our alphabet,
                                always more elaborate, always more complex, always more complete.
                            </p>
                            <p>
                                Carattere is the first chapter of <a href="/ricercasemica">Ricerc(a)semica</a>, a journey into form and meaning of language. 
                                The algorithm explores how characters grow and evolve from a single point to their most elaborate structure, 
                                how they behave in constrained or free environments, and how they influence each other.
                            </p>
                            <p>
                                Resolution agnostic, svg ready to be plotted, physicals available on request.
                            </p>
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}